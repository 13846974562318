import React, { ChangeEvent, useMemo } from 'react';
import { Field, Formik } from 'formik';
import { isEqual } from 'lodash';
import { FakesContext, useFakesUIContext } from '../FakesUIContext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { CategoryEnum, Filters } from '../FakesUIHelpers';
import { FormattedMessage, useIntl } from 'react-intl';

interface SearchFilter {
  category: string;
  searchText: string;
}

interface FilterFakes {
  path: string | undefined;
  hideDay: boolean;
  setHideDay: (hideDay: boolean) => void;
}

const prepareFilter = (queryParams: Filters, values: SearchFilter) => {
  const { category, searchText } = values;
  let { ...newQueryParams } = queryParams;
  delete newQueryParams.text;
  delete newQueryParams.category;
  if (category.length) {
    newQueryParams.category = category;
  }
  if (searchText.length > 3) {
    newQueryParams.text = searchText;
  }
  return newQueryParams;
};

export function FakesFilter({ hideDay, path, setHideDay }: FilterFakes) {
  // Fakes UI Context
  const fakesUIContext: Pick<FakesContext, 'queryParams' | 'setQueryParams'> = useFakesUIContext();
  const fakesUIProps: Pick<FakesContext, 'queryParams' | 'setQueryParams'> = useMemo(() => {
    return {
      queryParams: fakesUIContext.queryParams,
      setQueryParams: fakesUIContext.setQueryParams,
    };
  }, [fakesUIContext]);

  const intl = useIntl();

  // queryParams, setQueryParams,
  const applyFilter = (values: SearchFilter) => {
    const newQueryParams = prepareFilter(fakesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, fakesUIProps.queryParams)) {
      newQueryParams.page = 1;
      // update list by queryParams
      fakesUIProps.setQueryParams(newQueryParams);
    }
  };

  const toggleHideDay = () => {
    setHideDay(!hideDay);
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: '',
          category: '',
        }}
        onSubmit={values => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
          <form onSubmit={handleSubmit} className='form form-label-right'>
            <div className='form-group row'>
              <div className='col-lg-3 mb-5'>
                <input
                  type='text'
                  className='form-control'
                  name='searchText'
                  placeholder={intl.formatMessage({
                    id: 'TEXT.SEARCH',
                  })}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={e => {
                    setFieldValue('searchText', e.target.value);
                    handleSubmit();
                  }}
                />
                <small className='form-text text-muted'>
                  <FormattedMessage
                    id='FAKES.HEADER.FILTER.SEARCH'
                    values={{
                      b: (chunk: string) => <b>{chunk}</b>,
                    }}
                  />
                </small>
              </div>
              <div className='col-lg-3 mb-5'>
                <Field
                  id='categoryFilter'
                  name='category'
                  className='form-control'
                  as='select'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('category', event.target.value);
                    handleSubmit();
                  }}
                  value={values.category}
                  placeholder={intl.formatMessage({
                    id: 'TEXT.CATEGORY',
                  })}
                >
                  <option key={'--'} value={''}>
                    --
                  </option>
                  {Object.keys(CategoryEnum).map((key: string) => (
                    <option key={CategoryEnum[key as CategoryEnum]} value={CategoryEnum[key as CategoryEnum]}>
                      {CategoryEnum[key as CategoryEnum]}
                    </option>
                  ))}
                </Field>
                <small className='form-text text-muted'>
                  <FormattedMessage
                    id='FAKES.HEADER.FILTER.CATEGORY'
                    values={{
                      b: (chunk: string) => <b>{chunk}</b>,
                    }}
                  />
                </small>
              </div>
              {path === '/weeks' && (
                <div className='col-lg-3 mb-5'>
                  <button className='form-control' onClick={toggleHideDay}>
                    {!hideDay ? <VisibilityOffIcon /> : <VisibilityIcon />} <FormattedMessage id='FAKES.HEADER.BUTTON.WEEKDAYS' />
                  </button>
                  <small className='form-text text-muted'>
                    <FormattedMessage
                      id='FAKES.HEADER.SHOW.WEEKDAYS'
                      values={{
                        b: (chunk: string) => <b>{chunk}</b>,
                      }}
                    />
                  </small>
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
