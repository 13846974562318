import React, { useMemo } from "react";
import { FakesContext, useFakesUIContext } from "../FakesUIContext";

export function FakesGrouping() {
  // Fakes UI Context
  const fakesUIContext: Pick<
    FakesContext,
    "ids" | "setIds" | "openDeleteFakesDialog" | "openFetchFakesDialog"
  > = useFakesUIContext();
  const fakesUIProps: Pick<
    FakesContext,
    "ids" | "setIds" | "openDeleteFakesDialog" | "openFetchFakesDialog"
  > = useMemo(() => {
    return {
      ids: fakesUIContext.ids,
      setIds: fakesUIContext.setIds,
      openDeleteFakesDialog: fakesUIContext.openDeleteFakesDialog,
      openFetchFakesDialog: fakesUIContext.openFetchFakesDialog,
    };
  }, [fakesUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{fakesUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={fakesUIProps.openDeleteFakesDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
