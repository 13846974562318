import React from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';

import enMessages from './messages/en.json';
import esMessages from './messages/es.json';

interface I18nMessage {
  [key: string]: string;
}

interface I18nAllMessage {
  [key: string]: I18nMessage;
}

const allMessages: I18nAllMessage = {
  en: enMessages,
  es: esMessages,
};

interface I18nProviderProps {
  children: React.ReactElement;
}

export function I18nProvider({ children }: I18nProviderProps) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider wrapRichTextChunksInFragment={true} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
