import { FormikErrors, FormikTouched } from "formik";
import React, { JSXElementConstructor } from "react";
import { ChangeEvent } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

interface IGeneric {
  [key: string]: any;
}

interface InputFormikProps {
  errors: FormikErrors<IGeneric>;
  touched: FormikTouched<IGeneric>;
}

interface InputGenericProps {
  children?: React.ReactElement;
  id?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface InputProps {
  field: JSXElementConstructor<any>;
  form: InputFormikProps;
  label: string;
  withFeedbackLabel?: boolean;
  customFeedbackLabel: string;
  type?: string;
  props: InputGenericProps;
}

const getFieldCSSClasses = (
  touched?: boolean | FormikTouched<IGeneric> | FormikTouched<IGeneric>[],
  errors?: string | string[] | FormikErrors<IGeneric> | FormikErrors<IGeneric>[]
) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field,
  form: { touched, errors },
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}: InputProps) {
  return (
    <>
      {label && <label>Enter {label}</label>}
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name] as string}
          touched={touched[field.name] as boolean}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
