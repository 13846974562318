/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { PaginationOptions } from 'react-bootstrap-table-next';
import { getPages, getPagesCount } from './TablePaginationHelpers';

interface PaginationChildProps extends PaginationOptions {
  page: number;
  sizePerPage: number;
  totalSize: number;
  paginationSize: number;
  tableId?: string;
  bootstrap4?: boolean;
}

interface PaginationLinksProps {
  paginationProps: Partial<PaginationChildProps>;
}

export function PaginationLinks({ paginationProps }: PaginationLinksProps) {
  const { onPageChange } = paginationProps;
  const totalSize = paginationProps.totalSize ?? 0;
  const sizePerPage = paginationProps.sizePerPage ?? 0;
  const page = paginationProps.page ?? 0;
  const paginationSize = paginationProps.paginationSize ?? 0;
  const pagesCount = getPagesCount(totalSize, sizePerPage);
  const pages = getPages(page ?? 0, pagesCount, paginationSize);

  const handleFirstPage = () => {
    onPageChange && onPageChange(1, sizePerPage);
  };

  const handlePrevPage = () => {
    onPageChange && onPageChange(page - 1, sizePerPage);
  };

  const handleNextPage = () => {
    if (page < pagesCount) {
      onPageChange && onPageChange(page + 1, sizePerPage);
    }
  };

  const handleLastPage = () => {
    onPageChange && onPageChange(pagesCount, sizePerPage);
  };

  const handleSelectedPage = (pageNum: number) => {
    onPageChange && onPageChange(pageNum, sizePerPage);
  };

  const disabledClass = pagesCount > 1 ? '' : 'disabled';
  return (
    <>
      {page && pagesCount < 2 && <></>}
      {page && pagesCount > 1 && (
        <>
          <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
            <a onClick={() => handleFirstPage()} className='btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1'>
              <i className='ki ki-bold-double-arrow-back icon-xs' />
            </a>
            <a onClick={() => handlePrevPage()} className='btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1'>
              <i className='ki ki-bold-arrow-back icon-xs' />
            </a>

            {page > 1 && <a className='btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1'>...</a>}
            {pages.map(p => (
              <a
                key={p}
                onClick={() => handleSelectedPage(p)}
                className={`btn btn-icon btn-sm border-0 btn-light ${page === p ? ' btn-hover-primary active' : ''} mr-2 my-1`}
              >
                {p}
              </a>
            ))}
            {page < pagesCount && <a className='btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1'>...</a>}
            <a onClick={() => handleNextPage()} className='btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1'>
              <i className='ki ki-bold-arrow-next icon-xs'></i>
            </a>
            <a onClick={() => handleLastPage()} className='btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1'>
              <i className='ki ki-bold-double-arrow-next icon-xs'></i>
            </a>
          </div>
        </>
      )}
    </>
  );
}
