import { format, formatISO, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import React from 'react';

export function DayColumnFormatter(cellContent: string | Date) {
  if (cellContent instanceof Date) {
    cellContent = formatISO(cellContent);
  }
  const day = cellContent ? cellContent?.split('T')?.shift() : cellContent;
  return <p className='min-w-90px'>{day && format(parseISO(day), 'iiii', { locale: es })}</p>;
}
