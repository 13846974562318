import { Link } from "@material-ui/core";
import React from "react";

export function ImageColumnFormatter(cellContent: string) {
  return (
    <Link href={cellContent} target="_blank" rel="noreferrer">
      <img className="max-h-60px" alt={cellContent} src={cellContent} />
    </Link>
  );
}
