import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/Fakes/fakesActions";
import { FakeEditDialogHeader } from "./FakeEditDialogHeader";
import { FakeEditForm } from "./FakeEditForm";
import { Fake, FakesContext, useFakesUIContext } from "../FakesUIContext";
import _ from "lodash";
import { AppDispatch, RootState } from "../../../../redux/store";
import { AlertsRefObject } from "../../Alerts/Alert";

interface EditDialog {
  id?: string | null;
  show: boolean;
  path: string | undefined;
  onHide: () => void;
  alertRef: AlertsRefObject;
}

export function FakeEditDialog({
  id,
  show,
  path,
  onHide,
  alertRef,
}: EditDialog) {
  // Fakes UI Context
  const fakesUIContext: Pick<
    FakesContext,
    "queryParams" | "initFake" | "week"
  > = useFakesUIContext();
  const fakesUIProps: Pick<
    FakesContext,
    "queryParams" | "initFake" | "week"
  > = useMemo(() => {
    return {
      initFake: fakesUIContext.initFake,
      queryParams: fakesUIContext.queryParams,
      week: fakesUIContext.week,
    };
  }, [fakesUIContext]);

  // Fakes Redux state
  const dispatch = useDispatch<AppDispatch | any>();

  const { actionsLoading, fakeForEdit } = useSelector(
    (state: RootState) => ({
      actionsLoading: state.fakes.actionsLoading,
      fakeForEdit: state.fakes?.fakeForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Fake by id
    dispatch(actions.fetchFake(id ?? ""));
  }, [id, dispatch]);

  // server request for saving fake
  const saveFake = (fake: Fake) => {
    const fakeCleaned = _.omitBy(fake, _.isUndefined);
    let search = fakesUIProps.queryParams;
    if (path?.includes("/weeks")) {
      search = {
        ...fakesUIProps.queryParams,
        week: fakesUIProps.week,
        allDays: true,
      };
    }
    if (!id) {
      // server request for creating fake
      dispatch(actions.createFake(fakeCleaned as Fake)).then(() => {
        // refresh list after
        dispatch(actions.fetchFakes(search));
        onHide();
      });
      // dispatch(actions.createFake(fake)).then(() => onHide());
    } else {
      // server request for updating fake
      dispatch(actions.updateFake(fakeCleaned as Fake)).then(() => {
        // refresh list after
        dispatch(actions.fetchFakes(search));
        onHide();
      });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="modal-sizes-title-lg"
    >
      <FakeEditDialogHeader id={id} />
      <FakeEditForm
        edit={fakeForEdit ? true : false}
        alertRef={alertRef}
        saveFake={saveFake}
        actionsLoading={actionsLoading}
        fake={fakeForEdit || fakesUIProps.initFake}
        onHide={onHide}
      />
    </Modal>
  );
}
