import React, { JSXElementConstructor, ReactElement } from "react";
import { Route } from "react-router-dom";
import { Content } from "./Content";
import { History } from "history";
import { DashboardPageProps } from "../Pages/DashboardPage";
import { LoginProps } from "../Auth/Login";

interface ContentProps {
  path?: string;
  history?: History;
  children?: (
    routeProps: DashboardPageProps | LoginProps
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  component?: JSXElementConstructor<any>;
  render?: (
    routeProps: DashboardPageProps | LoginProps
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  props?: DashboardPageProps | LoginProps;
}

export function ContentRoute({
  path,
  history,
  children,
  component,
  render,
  ...props
}: ContentProps) {
  return (
    <Route {...props}>
      {(routeProps) => {
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
