import React from 'react';
import { PaginationOptions } from 'react-bootstrap-table-next';
import { PaginationLinks } from './PaginationLinks';
import { PaginationToolbar } from './PaginationToolbar';

interface PaginationChildProps extends PaginationOptions {
  tableId?: string;
  bootstrap4?: boolean;
}

interface PaginationProps {
  paginationProps: PaginationChildProps;
  isLoading: boolean;
  children: React.ReactElement;
}

export function Pagination(props: PaginationProps) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      {children}
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
        <PaginationToolbar isLoading={isLoading} paginationProps={paginationProps} />
        <PaginationLinks paginationProps={paginationProps} />
      </div>
    </>
  );
}
