import React from "react";

interface InputLabelProps {
  label: string;
  error: string;
  type?: string;
  touched: boolean;
  customFeedbackLabel: string;
}

const inputLabel = ({
  label,
  touched,
  error,
  customFeedbackLabel,
}: InputLabelProps) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }

  if (touched && !error && label) {
    return <div className="valid-feedback">{label} is correct</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
    </div>
  );
};

const selectLabel = ({
  label,
  touched,
  error,
  customFeedbackLabel,
}: InputLabelProps) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
    </div>
  );
};

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel,
}: InputLabelProps) {
  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "email":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "password":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}
