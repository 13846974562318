import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Layout } from './components/Layout/Layout';
import BasePage from './BasePage';
import Logout from './components/Auth/Logout';
import { AuthPage } from './components/Auth/AuthPage';
import ErrorsPage from './components/Errors/ErrorsPage';
import { History } from 'history';
import { AuthState } from '../redux/Auth/authRedux';

interface RoutesProps {
  history: History;
}

interface authProps {
  auth: AuthState;
}

export function Routes({ history }: RoutesProps) {
  const { isAuthorized } = useSelector(
    ({ auth }: authProps) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual,
  );

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <Layout>
          <BasePage history={history} />
        </Layout>
      )}
    </Switch>
  );
}
