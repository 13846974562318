import React, { ChangeEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from '@material-ui/core';
import { Input } from '../../Helpers/Input';
import { DatePickerField } from '../../Helpers/DatePickerField';
import { CategoryEnum } from '../FakesUIHelpers';
import { Fake } from '../FakesUIContext';
import { AlertsRefObject } from '../../Alerts/Alert';
import { useIntl, FormattedMessage } from 'react-intl';

const FILE_SIZE = 10000 * 1024;
const SUPPORTED_MEDIA = ['audio/mpeg', 'video/mp4'];
const SUPPORTED_IMAGES = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

// Validation schema
const FakeEditSchema = Yup.object().shape({
  date: Yup.mixed().nullable(false),
  transcript: Yup.mixed().nullable(true).optional(),
  tags: Yup.mixed().nullable(true).optional(),
  url: Yup.mixed().nullable(true).optional(),
});

interface FormFake extends Fake {
  fFile: string;
  fImage: string;
  fImageSmall: string;
}

interface EditForm {
  edit: boolean;
  alertRef: AlertsRefObject;
  actionsLoading: boolean;
  saveFake: (fake: Fake) => void;
  fake: Fake;
  onHide: () => void;
}

export function FakeEditForm({ edit, alertRef, saveFake, fake, actionsLoading, onHide }: EditForm) {
  const intl = useIntl();
  const [title, setTitle] = useState<string>();
  const [category, setCategory] = useState<string>();
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<File>();
  const [imageSmall, setImageSmall] = useState<File>();
  const [validateErrorCategory, setValidateErrorCategory] = useState<string>();
  const [validateErrorTitle, setValidateErrorTitle] = useState<string>();
  const [validateErrorUrl, setValidateErrorUrl] = useState<string>();
  const [validateErrorFile, setValidateErrorFile] = useState<string>();
  const [validateErrorImage, setValidateErrorImage] = useState<string>();
  const [validateErrorImageSmall, setValidateErrorImageSmall] = useState<string>();

  const showError = (error: string, field: string) => {
    alertRef.showAlert(error, 'error');
    document.querySelector(field)?.classList.add('is-invalid');
  };

  const isValid = (field: string) => {
    document.querySelector(field)?.classList.remove('is-invalid');
    document.querySelector(field)?.classList.add('is-valid');
  };

  const validateFile = (setErrors: (fields: { [field: string]: string }) => void, mediaFile?: File) => {
    let error: string = '';
    if (!mediaFile && !edit) {
      error = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.FILE.REQUIRED',
      });
    }
    if (mediaFile && mediaFile.size >= FILE_SIZE) {
      error = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.FILE.SIZE',
      });
    }
    if (mediaFile && !SUPPORTED_MEDIA.includes(mediaFile.type)) {
      error = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.FILE.FORMAT',
      });
    }
    setFile(mediaFile as any);
    if (error) {
      setValidateErrorFile(error);
      showError(error, '#fFile');
      setErrors({ fFile: error });
      return;
    }
    setValidateErrorFile(undefined);
    isValid('#fFile');
  };

  const validateImage = (setErrors: (fields: { [field: string]: string }) => void, imageFile?: File) => {
    let error: string = '';
    if (imageFile && imageFile.size >= FILE_SIZE) {
      error = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.FILE.SIZE',
      });
    }
    if (imageFile && !SUPPORTED_IMAGES.includes(imageFile.type)) {
      error = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.IMAGE.FORMAT',
      });
    }
    setImage(imageFile as any);
    if (error) {
      setValidateErrorImage(error);
      showError(error, '#fImage');
      setErrors({ fImage: error });
      return;
    }
    setValidateErrorImage(undefined);
    isValid('#fImage');
  };

  const validateImageSmall = (setErrors: (fields: { [field: string]: string }) => void, imageFileSmall?: File) => {
    let error: string = '';
    if (imageFileSmall && imageFileSmall.size >= FILE_SIZE) {
      error = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.FILE.SIZE',
      });
    }
    if (imageFileSmall && !SUPPORTED_IMAGES.includes(imageFileSmall.type)) {
      error = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.IMAGE.FORMAT',
      });
    }
    setImageSmall(imageFileSmall as any);
    if (error) {
      setValidateErrorImageSmall(error);
      showError(error, '#fImageSmall');
      setErrors({ fImageSmall: error });
      return;
    }
    setValidateErrorImageSmall(undefined);
    isValid('#fImageSmall');
  };

  const validateTitle = (setErrors: (fields: { [field: string]: string }) => void, vtitle: string) => {
    setTitle(vtitle);
    if (!vtitle) {
      const error: string = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.TITLE.REQUIRED',
      });
      setValidateErrorTitle(error);
      showError(error, '#title');
      setErrors({ title: error });
      return;
    }
    setValidateErrorTitle(undefined);
    isValid('#title');
  };

  const validateUrl = (setErrors: (fields: { [field: string]: string }) => void, vurl: string) => {
    if (vurl) {
      try {
        new URL(vurl);
      } catch {
        const error: string = intl.formatMessage({
          id: 'FAKES.FORM.VALIDATION.URL.INVALID',
        });
        setValidateErrorUrl(error);
        showError(error, '#url');
        setErrors({ url: error });
        return;
      }
    }

    setValidateErrorUrl(undefined);
    isValid('#url');
  };

  const validateCategory = (setErrors: (fields: { [field: string]: string }) => void, vcategory: string) => {
    setCategory(vcategory);
    if (!vcategory) {
      const error: string = intl.formatMessage({
        id: 'FAKES.FORM.VALIDATION.CATEGORY.REQUIRED',
      });
      setValidateErrorCategory(error);
      showError(error, '#category');
      setErrors({ category: error });
      return;
    }
    setValidateErrorCategory(undefined);
    isValid('#category');
  };

  const validateTranscript = () => {
    isValid('#transcript');
  };

  const errorsTitle = () => {
    return validateErrorTitle;
  };
  const errorsUrl = () => {
    return validateErrorUrl;
  };
  const errorsCategory = () => {
    return validateErrorCategory;
  };
  const errorsFile = () => {
    return validateErrorFile;
  };
  const errorsImage = () => {
    return validateErrorImage;
  };
  const errorsImageSmall = () => {
    return validateErrorImageSmall;
  };

  const validateCustom = (values: Fake, setErrors: (fields: { [field: string]: string }) => void) => {
    validateTitle(setErrors, values.title);
    validateUrl(setErrors, values.url);
    validateCategory(setErrors, values.category);
    validateTranscript();
    validateFile(setErrors, file);
    validateImage(setErrors, image);
    validateImageSmall(setErrors, imageSmall);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={fake as FormFake}
        validationSchema={FakeEditSchema}
        onSubmit={values => {
          const cloneFake: Fake = { ...values };
          if (!cloneFake.image) delete cloneFake.image;
          if (!cloneFake.imageSmall) delete cloneFake.imageSmall;
          if (category) cloneFake.category = category;
          if (file) cloneFake.file = file;
          if (image) cloneFake.image = image;
          if (imageSmall) cloneFake.imageSmall = imageSmall;
          saveFake(cloneFake);
        }}
      >
        {({ errors, handleSubmit, handleBlur, setErrors, setFieldValue, touched, validateForm, values }) => (
          <>
            <Modal.Body className='overlay overlay-block cursor-default'>
              {actionsLoading && (
                <div className='overlay-layer bg-transparent'>
                  <div className='spinner spinner-lg spinner-success' />
                </div>
              )}
              <Form className='form form-label-right'>
                <div className='form-group row'>
                  <div className='col-lg-12 p-1'>
                    <label className='label-input-required'>
                      <FormattedMessage id='TEXT.TITLE' />
                    </label>
                    <Field
                      id='title'
                      name='title'
                      component={Input}
                      validate={errorsTitle}
                      onBlur={handleBlur}
                      value={title ? title : values.title}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('title', event.target.value);
                        validateTitle(setErrors, event.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: 'TEXT.TITLE',
                      })}
                    />
                  </div>
                  <div className='col-lg-12 p-1'>
                    <label>
                      <FormattedMessage id='TEXT.TRANSCRIPT' />
                    </label>
                    <Field
                      id='transcript'
                      name='transcript'
                      className='form-control'
                      as='textarea'
                      rows={3}
                      onBlur={handleBlur}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('transcript', event.target.value);
                        validateTranscript();
                      }}
                      placeholder={intl.formatMessage({
                        id: 'TEXT.TRANSCRIPT',
                      })}
                    />
                    <div className='invalid-feedback'>{errors.transcript}</div>
                    {errors.transcript && touched.transcript}
                  </div>
                  <div className='col-lg-12 p-1'>
                    <label>
                      <FormattedMessage id='TEXT.URL' />
                    </label>
                    <Field
                      id='url'
                      name='url'
                      component={Input}
                      validate={errorsUrl}
                      placeholder='https://...'
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('url', event.target.value);
                        validateUrl(setErrors, event.target.value);
                      }}
                    />
                  </div>
                  <div className='col-lg-12 p-1'>
                    <label className='label-input-required'>
                      <FormattedMessage id='TEXT.CATEGORY' />
                    </label>
                    <Field
                      id='category'
                      name='category'
                      className='form-control'
                      as='select'
                      value={category ? category : values.category}
                      validate={errorsCategory}
                      onBlur={handleBlur}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('category', event.target.value);
                        validateCategory(setErrors, event.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: 'TEXT.CATEGORY',
                      })}
                    >
                      <option key={'--'} value={''}>
                        --
                      </option>
                      {Object.keys(CategoryEnum).map((key: string) => (
                        <option key={CategoryEnum[key as CategoryEnum]} value={CategoryEnum[key as CategoryEnum]}>
                          {CategoryEnum[key as CategoryEnum]}
                        </option>
                      ))}
                    </Field>
                    <div className='invalid-feedback'>{errors.category}</div>
                    {errors.category && touched.category}
                  </div>
                  <div className='col-lg-12 p-1'>
                    <label className='label-input-required'>
                      <FormattedMessage id='TEXT.DATE' />
                    </label>
                    <DatePickerField classDate='width-date-picker' dateFormat='yyyy-MM-dd' id='date' name='date' />
                  </div>
                  <div className='col-lg-12 p-1'>
                    <label>
                      <FormattedMessage id='TEXT.TAGS' />
                    </label>
                    <Field
                      id='tags'
                      name='tags'
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: 'TEXT.TAGS',
                      })}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-lg-6 p-1'>
                    <label className='label-input-required'>
                      <FormattedMessage id='TEXT.FILE' />
                    </label>
                    <Field
                      id='fFile'
                      name='fFile'
                      type='file'
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: 'TEXT.FILE',
                      })}
                      validate={errorsFile}
                      onBlur={handleBlur}
                      onChange={(event: ChangeEvent) => {
                        const fileSource = event.currentTarget as HTMLInputElement;
                        if (fileSource?.files?.length) {
                          validateFile(setErrors, fileSource.files[0]);
                        }
                      }}
                    />
                  </div>
                  <div className='col-lg-6 p-1'>
                    <audio controls src={fake.file as string} />
                  </div>
                  <div className='col-lg-6 p-1'>
                    <label>
                      <FormattedMessage id='TEXT.IMAGE' />
                    </label>
                    <Field
                      id='fImage'
                      name='fImage'
                      type='file'
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: 'TEXT.IMAGE',
                      })}
                      validate={errorsImage}
                      onBlur={handleBlur}
                      onChange={(event: ChangeEvent) => {
                        const fileSource = event.currentTarget as HTMLInputElement;
                        if (fileSource?.files?.length) {
                          validateImage(setErrors, fileSource.files[0]);
                        }
                      }}
                    />
                  </div>
                  <div className='col-lg-6 p-1'>
                    <Link href={fake.image as string} target='_blank' rel='noreferrer'>
                      <img className='max-h-60px' alt={fake.image as string} src={fake.image as string} />
                    </Link>
                  </div>
                  <div className='col-lg-6 p-1'>
                    <label>
                      <FormattedMessage id='TEXT.THUMBNAIL' />
                    </label>
                    <Field
                      id='fImageSmall'
                      name='fImageSmall'
                      type='file'
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: 'TEXT.THUMBNAIL',
                      })}
                      validate={errorsImageSmall}
                      onBlur={handleBlur}
                      onChange={(event: ChangeEvent) => {
                        const fileSource = event.currentTarget as HTMLInputElement;
                        if (fileSource?.files?.length) {
                          validateImageSmall(setErrors, fileSource.files[0]);
                        }
                      }}
                    />
                  </div>
                  <div className='col-lg-6 p-1'>
                    <Link href={fake.imageSmall as string} target='_blank' rel='noreferrer'>
                      <img className='max-h-60px' alt={fake.imageSmall as string} src={fake.imageSmall as string} />
                    </Link>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                <FormattedMessage id='TEXT.CANCEL' />
              </button>
              <> </>
              <button
                type='submit'
                onClick={() => {
                  validateForm().then(() => {
                    validateCustom(values, setErrors);
                    handleSubmit();
                  });
                }}
                className='btn btn-primary btn-elevate'
              >
                <FormattedMessage id='TEXT.SAVE' />
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
