import React, { useCallback, useEffect, useState } from 'react';
import { FormikProps, FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import * as auth from '../../../redux/Auth/authRedux';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { AxiosError } from 'axios';
import { AmplifyConfig } from './AmplifyConfig';
import Amplify, { Auth } from 'aws-amplify';
import SVG from 'react-inlinesvg';

interface Values extends FormikValues {
  email: string;
  password: string;
}

const initialValues = {
  email: 'user',
  password: 'password',
};

export interface LoginProps {
  intl: IntlShape;
  login: Function;
  logout: Function;
  requestUser: Function;
  fulfillUser: Function;
  setUser: Function;
}

function Login(props: LoginProps) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.INVALID_MAIL',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD',
        }) + 3,
      )
      .max(
        50,
        intl.formatMessage({
          id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD',
        }) + 50,
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname: string) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const signIn = async (username: string, password: string) => {
    try {
      Amplify.configure(AmplifyConfig);
      return Auth.signIn(username, password);
    } catch (error) {
      console.warn('error signing in', error);
    }
  };

  const signInGoogle = () => {
    const authInstance = window?.gapi?.auth2?.getAuthInstance();
    authInstance.signIn().then(
      (googleUser: gapi.auth2.GoogleUser) => {
        getAWSCredentials(googleUser);
      },
      (error: unknown) => {
        console.warn('error signing in', error);
      },
    );
  };

  const getAWSCredentials = async (googleUser: gapi.auth2.GoogleUser) => {
    const { id_token, expires_at } = googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();
    const user = {
      email: profile.getEmail(),
      username: profile.getName(),
      accessToken: id_token,
      expires: expires_at,
    };
    props.login(user);
  };

  const createScript = useCallback(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    script.onload = initGapi;
    document.body.appendChild(script);
  }, []);

  const initGapi = () => {
    const g = window.gapi;
    g.load('auth2', () => {
      g.auth2.init({
        client_id: window._env_.REACT_APP_COGNITO_GOOGLEID,
        scope: 'profile email openid',
      });
    });
  };

  useEffect(() => {
    const authInstance = window.gapi?.auth2?.getAuthInstance() ?? null;
    if (!authInstance) createScript();
  }, [createScript]);

  const formik: FormikProps<Values> = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        signIn(values.email, values.password)
          .then(({ signInUserSession }) => {
            const user = {
              email: values.email,
              username: signInUserSession?.idToken?.payload?.['cognito:username'],
              accessToken: signInUserSession?.idToken?.jwtToken,
              expires: signInUserSession?.idToken?.payload?.exp,
            };
            disableLoading();
            props.login(user);
          })
          .catch((err: AxiosError) => {
            disableLoading();
            setSubmitting(false);
            if (err.response?.status === 412) {
              setStatus(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_HOST',
                }),
              );
            } else {
              setStatus(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_LOGIN',
                }),
              );
            }
          });
      }, 1000);
    },
  });

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10'>
        <img alt='Logo' className='h-200px h-md-250px mb-10' src={toAbsoluteUrl('/media/logos/logo_bulopedia_fondo_blanco_3.png')} />
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.LOGIN.DESCRIPTION' />
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
        {formik.status ? (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Email'
            type='email'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Password'
            type='password'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between flex-row-reverse'>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className='btn btn-primary font-weight-bold px-9 py-4 my-3 col-12'
          >
            <FormattedMessage id='AUTH.LOGIN.BUTTON' />
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
      <button className='btn btn-secondary font-weight-bold px-9 py-4 my-3 col-12' onClick={signInGoogle}>
        <span className='svg-icon svg-icon-lg'>
          <SVG src={toAbsoluteUrl('/media/svg/google-icon.svg')} />
        </span>
        <FormattedMessage id='AUTH.LOGIN.BUTTON.GOOGLE' />
      </button>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
