enum Order {
  asc = 'asc',
  desc = 'desc',
}

interface Sorted {
  dataField: string;
  order: Order;
}

export interface SizePage {
  text: string;
  value: number;
}

export enum CategoryEnum {
  Personajes = 'Personajes',
  Ciencia = 'Ciencia',
  Política = 'Política',
  Salud = 'Salud',
  Marcas = 'Marcas',
  Tecnología = 'Tecnología',
  Otros = 'Otros',
}

export interface Filters {
  page: number;
  limit: number;
  orderBy: string;
  orderDirection: Order;
  text?: string;
  category?: string;
  week?: string;
  allDays?: boolean;
}

export const defaultSorted: Sorted[] = [{ dataField: 'date', order: Order.desc }];
export const defaultWeekSorted: Sorted[] = [{ dataField: 'date', order: Order.asc }];
export const sizePerPageList: SizePage[] = [
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '50', value: 50 },
];
export const initialFilter: Filters = {
  page: 1,
  limit: 10,
  orderBy: 'date',
  orderDirection: Order.asc,
};
