import { Filters } from "../../app/components/Fakes/FakesUIHelpers";
import * as requestFromServer from "../../api/fakesCrud";
import { fakesSlice, callTypes, FakeStateAction } from "./fakesSlice";
import { Fake } from "../../app/components/Fakes/FakesUIContext";
import { Dispatch } from "react";
import { AuthStateAction } from "../../redux/Auth/authRedux";

const { actions } = fakesSlice;

export const fetchFakes = (queryParams: Filters) => async (
  dispatch: Dispatch<FakeStateAction | AuthStateAction>
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.findFakes(queryParams);
    const entities = response.data;
    const headers = response.headers;
    const totalCount: number = parseInt(headers["pagination-count"]);
    dispatch(actions.fakesFetched({ totalCount, entities }));
  } catch (error) {
    if (!error.config.adapter) window.location.href = "/";
    error.clientMessage = "Can't find fakes";
    dispatch(
      actions.catchError({ catchError: error, callType: callTypes.list })
    );
  }
};

export const fetchFake = (id: string) => async (
  dispatch: Dispatch<FakeStateAction | AuthStateAction>
) => {
  if (!id) {
    dispatch(actions.fakeFetched({ fakeForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getFakeById(id);
    const fake = response.data;
    fake.date = fake.date.split("T").shift();
    dispatch(actions.fakeFetched({ fakeForEdit: fake }));
  } catch (error) {
    error.clientMessage = "Can't find fake";
    dispatch(
      actions.catchError({ catchError: error, callType: callTypes.action })
    );
  }
};

export const deleteFake = (id: string) => async (
  dispatch: Dispatch<FakeStateAction | AuthStateAction>
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteFake(id);
    dispatch(actions.fakeDeleted({ id }));
  } catch (error) {
    error.clientMessage = "Can't delete fake";
    dispatch(
      actions.catchError({ catchError: error, callType: callTypes.action })
    );
  }
};

export const createFake = (fakeForCreation: Fake) => async (
  dispatch: Dispatch<FakeStateAction | AuthStateAction>
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createFake(fakeForCreation);
    const fake = response.data;
    if (fake) dispatch(actions.fakeCreated({ fake }));
  } catch (error) {
    error.clientMessage = "Can't create fake";
    dispatch(
      actions.catchError({ catchError: error, callType: callTypes.action })
    );
  }
};

export const updateFake = (fake: Fake) => async (
  dispatch: Dispatch<FakeStateAction | AuthStateAction>
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.updateFake(fake);
    dispatch(actions.fakeUpdated({ fake }));
  } catch (error) {
    error.clientMessage = "Can't update fake";
    dispatch(
      actions.catchError({ catchError: error, callType: callTypes.action })
    );
  }
};

export const deleteFakes = (ids: string[]) => async (
  dispatch: Dispatch<FakeStateAction | AuthStateAction>
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    await requestFromServer.deleteFakes(ids);
    dispatch(actions.fakesDeleted({ ids }));
  } catch (error) {
    error.clientMessage = "Can't delete fakes";
    dispatch(
      actions.catchError({ catchError: error, callType: callTypes.action })
    );
  }
};
