import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Fake, initFake } from "../../app/components/Fakes/FakesUIContext";

interface PayLoadError extends AxiosError {
  clientMessage: string;
}

export interface FakesState {
  actionsLoading?: boolean;
  entities?: Fake[];
  error?: string | null;
  fakeForEdit?: Fake;
  lastError?: string | null;
  listLoading?: boolean;
  totalCount?: number;
  catchError?: PayLoadError;
}

const initialFakesState = {
  actionsLoading: false,
  entities: [],
  error: "",
  fakeForEdit: initFake,
  lastError: "",
  listLoading: false,
  totalCount: 0,
};

export const callTypes = {
  action: "action",
  list: "list",
};

interface PayLoadAction extends FakesState {
  callType?: string;
  fake?: Fake;
  id?: string;
  ids?: string[];
}

export interface FakeStateAction extends AnyAction {
  payload: PayLoadAction;
  type: string;
}

export const fakesSlice = createSlice({
  name: "fakes",
  initialState: initialFakesState,
  reducers: {
    catchError: (state: FakesState, action: FakeStateAction) => {
      const error = action.payload?.catchError?.response?.data?.error;
      const statusCode = action.payload?.catchError?.response?.data?.statusCode;
      const clientMessage = action.payload?.catchError?.clientMessage;
      const message = action.payload?.catchError?.response?.data?.message?.toString();
      if (statusCode)
        state.error = `${error} ${statusCode}: ${clientMessage} ${message}`;
      if (action.payload?.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: FakesState, action: FakeStateAction) => {
      state.error = null;
      if (action.payload?.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFakeById
    fakeFetched: (state: FakesState, action: FakeStateAction) => {
      state.actionsLoading = false;
      state.fakeForEdit = action.payload?.fakeForEdit;
      state.error = null;
    },
    // findFakes
    fakesFetched: (state: FakesState, action: FakeStateAction) => {
      const payload = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = payload?.entities;
      state.totalCount = payload?.totalCount;
    },
    // createFake
    fakeCreated: (state: FakesState, action: FakeStateAction) => {
      state.actionsLoading = false;
      state.error = null;
      if (state.entities && action.payload?.fake) {
        state.entities.push(action.payload?.fake);
      }
    },
    // updateFake
    fakeUpdated: (state: FakesState, action: FakeStateAction) => {
      state.error = null;
      state.actionsLoading = false;
      if (state.entities) {
        state.entities = state.entities.map((entity: Fake) => {
          if (entity._id === action.payload?.fake?._id) {
            return action.payload?.fake;
          }
          return entity;
        }) as Fake[];
      }
    },
    // deleteFake
    fakeDeleted: (state: FakesState, action: FakeStateAction) => {
      state.error = null;
      state.actionsLoading = false;
      if (state.entities) {
        state.entities = state.entities.filter(
          (el: Fake) => el._id !== action.payload?.id
        );
      }
    },
    // deleteFakes
    fakesDeleted: (state: FakesState, action: FakeStateAction) => {
      state.error = null;
      state.actionsLoading = false;
      if (state.entities) {
        state.entities = state.entities.filter(
          (el: Fake) => !action.payload?.ids?.includes(el._id as string)
        );
      }
    },
  },
});
