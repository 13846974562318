/**
 * Entry application component used to compose providers and render Routes.
 * */

import { EnhancedStore } from '@reduxjs/toolkit';
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from '../app/Routes';
import { I18nProvider } from '../app/components/i18n';
import { LayoutSplashScreen } from '../_metronic/layout';
import { MaterialThemeProvider } from './components/Helpers/MaterialThemeProvider';
import { History } from 'history';

interface AppProps {
  history: History;
  store: EnhancedStore;
  persistor: Persistor;
  basename: string;
}

declare global {
  interface Window {
    gapi: any;
  }
}

export default function App({ history, store, persistor, basename = '/' }: AppProps) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <HashRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes history={history} />
              </I18nProvider>
            </MaterialThemeProvider>
          </HashRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
