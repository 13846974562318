import React from "react";
import { formatISO } from "date-fns";

export function DateColumnFormatter(cellContent: string | Date) {
  if (cellContent instanceof Date) {
    cellContent = formatISO(cellContent);
  }
  const day = cellContent ? cellContent?.split("T")?.shift() : cellContent;
  return <p className="min-w-90px">{day}</p>;
}
