import React from "react";
import {
  FormikErrors,
  FormikTouched,
  useField,
  useFormikContext,
} from "formik";
import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";

interface DateField {
  dateFormat: string;
  id: string;
  label?: string;
  classDate?: string;
  name: string;
}

interface IGeneric {
  [key: string]: string;
}

interface DateFormikContext {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  errors: FormikErrors<IGeneric>;
  touched: FormikTouched<IGeneric>;
}

const getFieldCSSClasses = (
  touched?: boolean | FormikTouched<IGeneric> | FormikTouched<IGeneric>[],
  errors?:
    | string
    | string[]
    | FormikErrors<IGeneric>
    | FormikErrors<IGeneric>[],
  classDate?: string
) => {
  const classes = ["form-control"];
  if (classDate) {
    classes.push(classDate);
  }
  if (touched && errors) {
    classes.push("is-invalid");
  }
  if (touched && !errors) {
    classes.push("is-valid");
  }
  return classes.join(" ");
};

export function DatePickerField({ ...props }: DateField) {
  const {
    setFieldValue,
    errors,
    touched,
  }: DateFormikContext = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        locale={es}
        className={getFieldCSSClasses(
          touched[field.name],
          errors[field.name],
          props.classDate
        )}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || new Date()}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">{errors[field.name]}</div>
      ) : (
        <div className="feedback"></div>
      )}
    </>
  );
}
