//@ts-nocheck
import React, { useCallback, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { FakesFilter } from './fakes-filter/FakesFilter';
import { FakesTable } from './fakes-table/FakesTable';
import { FakesGrouping } from './fakes-grouping/FakesGrouping';
import { useFakesUIContext } from './FakesUIContext';
import { FakesWeekTable } from './fakes-table/FakesWeekTable';
import { add, getISOWeek, getYear, parseISO, sub } from 'date-fns';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

interface FakesContentProps {
  path: string | undefined;
}

export function FakesContent({ path }: FakesContentProps) {
  const fakesUIContext: FakesContext = useFakesUIContext();
  const fakesUIProps = useMemo(() => {
    return {
      ids: fakesUIContext.ids,
      newFakeButtonClick: fakesUIContext.newFakeButtonClick,
      initWeek: fakesUIContext.initWeek,
      week: fakesUIContext.week,
      setWeek: fakesUIContext.setWeek,
    };
  }, [fakesUIContext]);

  const intl = useIntl();

  const today = new Date().setHours(0, 0, 0, 0);
  const currentYear = getYear(today);
  const [hideDay, setHideDay] = useState(false);

  const currentWeek = useCallback(() => {
    fakesUIProps.setWeek(fakesUIProps.initWeek);
  }, [fakesUIProps]);

  const prevWeek = useCallback(() => {
    const date = parseISO(fakesUIProps.week);
    const prev = sub(date, { weeks: 1 });
    let prevIsoWeek = getISOWeek(prev);
    if (prevIsoWeek < 10) {
      prevIsoWeek = String(prevIsoWeek).padStart(2, '0');
    }
    fakesUIProps.setWeek(`${currentYear}W${prevIsoWeek}`);
  }, [currentYear, fakesUIProps]);

  const nextWeek = useCallback(() => {
    const date = parseISO(fakesUIProps.week);
    const next = add(date, { weeks: 1 });
    let nextIsoWeek = getISOWeek(next);
    if (nextIsoWeek < 10) {
      nextIsoWeek = String(nextIsoWeek).padStart(2, '0');
    }
    fakesUIProps.setWeek(`${currentYear}W${nextIsoWeek}`);
  }, [currentYear, fakesUIProps]);

  return (
    <Card>
      <CardHeader
        title={
          path === '/fakes'
            ? intl.formatMessage({
                id: 'FAKES.HEADER.TITLE.LIST',
              })
            : intl.formatMessage({
                id: 'FAKES.HEADER.TITLE.WEEK',
              })
        }
      >
        <CardHeaderToolbar>
          <button type='button' className='btn btn-primary' onClick={fakesUIProps.newFakeButtonClick}>
            <FormattedMessage id='FAKES.HEADER.NEW.FAKE' />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <FakesFilter path={path} hideDay={hideDay} setHideDay={setHideDay} />
        {path === '/weeks' && (
          <div className='d-flex align-items-center justify-content-between'>
            <OverlayTrigger
              placement='auto'
              overlay={
                <Tooltip id='prev-week'>
                  <FormattedMessage id='FAKES.TABLE.HEADER.PREVIOUS.WEEK' />
                </Tooltip>
              }
            >
              <button type='button' className='btn btn-primary' onClick={prevWeek}>
                <ChevronLeftIcon />
              </button>
            </OverlayTrigger>
            <div>
              <Typography align='center' variant='h6' className='font-weight-bold'>
                <FormattedMessage id='FAKES.TABLE.HEADER.WEEK' /> {fakesUIProps.week.split('W').pop()}
              </Typography>
              <Link className='font-weight-bold' onClick={currentWeek}>
                <FormattedMessage id='FAKES.TABLE.HEADER.CURRENT' />
              </Link>
            </div>
            <OverlayTrigger
              placement='auto'
              overlay={
                <Tooltip id='next-week'>
                  <FormattedMessage id='FAKES.TABLE.HEADER.NEXT.WEEK' />
                </Tooltip>
              }
            >
              <button type='button' className='btn btn-primary' onClick={nextWeek}>
                <ChevronRightIcon />
              </button>
            </OverlayTrigger>
          </div>
        )}
        {fakesUIProps.ids.length > 0 && <FakesGrouping />}
        {path === '/fakes' ? <FakesTable /> : <FakesWeekTable hideDay={hideDay} />}
      </CardBody>
    </Card>
  );
}
