import React from "react";
import {
  FakesWeekPage
} from "./FakesWeekPage";
import { History } from "history";

export interface DashboardPageProps {
  history: History;
}

export function DashboardPage({ history }: DashboardPageProps) {
  return <FakesWeekPage history={history} />;
}
