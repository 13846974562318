/* eslint-disable no-unused-vars */
import React, { ChangeEvent } from 'react';
import { PaginationOptions } from 'react-bootstrap-table-next';
import { FormattedMessage } from 'react-intl';
import { SizePage } from '../Fakes/FakesUIHelpers';

interface PaginationChildProps extends PaginationOptions {
  page: number;
  sizePerPageList: number[] | SizePage[];
  sizePerPage: number;
  totalSize: number;
  paginationSize: number;
  tableId?: string;
  bootstrap4?: boolean;
}

interface PaginationToolbarProps {
  paginationProps: Partial<PaginationChildProps>;
  isLoading: boolean;
}

export function PaginationToolbar(props: PaginationToolbarProps) {
  const { isLoading, paginationProps } = props;
  const { onSizePerPageChange } = paginationProps;

  const totalSize = paginationProps.totalSize ?? 0;
  const sizePerPage = paginationProps.sizePerPage ?? 0;
  const page = paginationProps.page ?? 0;
  const pageStartIndex = paginationProps.pageStartIndex ?? 0;

  const sizePerPageList = paginationProps.sizePerPageList as SizePage[];

  const from = () => {
    let current = (page - pageStartIndex) * sizePerPage;
    return (current = totalSize === 0 ? 0 : current + 1);
  };

  const to = () => {
    const offset = Math.abs(1 - pageStartIndex);
    let current = Math.min(sizePerPage * (page + offset), totalSize);
    if (current > totalSize) current = totalSize;
    return (current = totalSize === 0 ? 0 : current);
  };

  const style = {
    width: '75px',
  };

  const onSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSize = +event.target.value;
    if (onSizePerPageChange && page) {
      onSizePerPageChange(newSize, page);
    }
  };

  return (
    <div className='d-flex align-items-center py-3'>
      {isLoading && (
        <div className='d-flex align-items-center'>
          <div className='mr-2 text-muted'>
            <FormattedMessage id='TEXT.LOADING' />
          </div>
          <div className='spinner spinner-primary mr-10'></div>
        </div>
      )}
      <select
        disabled={totalSize === 0}
        className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${totalSize === 0 && 'disabled'}`}
        onChange={onSizeChange}
        value={sizePerPage}
        style={style}
      >
        {sizePerPageList &&
          sizePerPageList.map((option: SizePage) => {
            const isSelect = sizePerPage === (option.value as number);
            return (
              <option key={option.text} value={option.value} className={`btn ${isSelect ? 'active' : ''}`}>
                {option.text}
              </option>
            );
          })}
      </select>
      {sizePerPage && page && (
        <span>
          <FormattedMessage id='FAKES.TABLE.PAGINATION.INFO.FROM' /> {from()}
          <FormattedMessage id='FAKES.TABLE.PAGINATION.INFO.TO' /> {to()}
          <FormattedMessage id='FAKES.TABLE.PAGINATION.INFO.TOTAL' /> {totalSize}
        </span>
      )}
    </div>
  );
}
