import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { Filters, initialFilter } from "./FakesUIHelpers";
import { getISOWeek, getYear } from "date-fns";

const today = new Date().setHours(0, 0, 0, 0);
const currentYear = getYear(today);
const currentWeek = getISOWeek(today);
const initWeek = `${currentYear}W${currentWeek}`;

export interface Fake {
  _id: string;
  title: string;
  transcript?: string;
  file: string | File;
  image?: string | File;
  imageSmall?: string | File;
  url: string;
  category: string;
  date: Date;
  tags?: string[];
}

export const initFake: Fake = {
  _id: "",
  title: "",
  transcript: "",
  file: "",
  image: "",
  imageSmall: "",
  url: "",
  category: "",
  date: new Date(),
  tags: [],
};
export interface FakesUIEvents {
  newFakeButtonClick: () => void;
  openEditFakeDialog: (id?: string) => void;
  openDeleteFakeDialog: (id?: string) => void;
  openDeleteFakesDialog: () => void;
  openFetchFakesDialog: () => void;
}

export interface FakesContext {
  queryParams: Filters;
  ids: string[];
  initFake: Fake;
  initWeek: string;
  week: string;
  setIds: (ids: string[]) => void;
  setQueryParamsBase: (queryParams: Filters) => void;
  setQueryParams: (nextQueryParams: Filters) => void;
  setWeek: (initWeek: string) => void;
  newFakeButtonClick: () => void;
  openEditFakeDialog: (id?: string) => void;
  openDeleteFakeDialog: (id?: string) => void;
  openDeleteFakesDialog: () => void;
  openFetchFakesDialog: () => void;
}

const initContext = {
  queryParams: initialFilter,
  ids: [""],
  initFake: initFake,
  initWeek: initWeek,
  week: initWeek,
  setIds: (ids: string[]) => {},
  setQueryParamsBase: (queryParams: Filters) => {},
  setQueryParams: (nextQueryParams: Filters) => {},
  setWeek: (initWeek: string) => {},
  newFakeButtonClick: () => {},
  openEditFakeDialog: (id?: string) => {},
  openDeleteFakeDialog: (id?: string) => {},
  openDeleteFakesDialog: () => {},
  openFetchFakesDialog: () => {},
};

const FakesUIContext = createContext(initContext);

export function useFakesUIContext() {
  return useContext(FakesUIContext);
}

export const FakesUIConsumer = FakesUIContext.Consumer;

interface FakesProvider {
  fakesUIEvents: FakesUIEvents;
  children: React.ReactNode;
}

export function FakesUIProvider({ fakesUIEvents, children }: FakesProvider) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState<string[]>([]);
  const [week, setWeek] = useState(initWeek);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams: Filters) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value: FakesContext = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initFake,
    initWeek,
    week,
    setWeek,
    newFakeButtonClick: fakesUIEvents.newFakeButtonClick,
    openEditFakeDialog: fakesUIEvents.openEditFakeDialog,
    openDeleteFakeDialog: fakesUIEvents.openDeleteFakeDialog,
    openDeleteFakesDialog: fakesUIEvents.openDeleteFakesDialog,
    openFetchFakesDialog: fakesUIEvents.openFetchFakesDialog,
  };

  return (
    <FakesUIContext.Provider value={value}>{children}</FakesUIContext.Provider>
  );
}
