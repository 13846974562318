import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { ContentRoute } from "./components/Content/ContentRoute";
import { DashboardPage } from "./components/Pages/DashboardPage";
import { FakesWeekPage } from "./components/Pages/FakesWeekPage";
import { FakesPage } from "./components/Pages/FakesPage";
import { History } from "history";

interface BasePageProps {
  history: History;
}

export default function BasePage({ history }: BasePageProps) {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/weeks" component={FakesWeekPage} />
        <Route path="/fakes" component={FakesPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
