/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { SyntheticEvent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { Fake } from '../../FakesUIContext';

interface OpenActionsColumn {
  openEditFakeDialog: (id: string) => void;
  openDeleteFakeDialog: (id: string) => void;
}

export function ActionsColumnFormatter(
  cellContent: string,
  row: Fake,
  rowIndex: number,
  { openEditFakeDialog, openDeleteFakeDialog }: OpenActionsColumn,
) {
  return (
    <>
      <a
        href=''
        className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          openEditFakeDialog(row._id);
        }}
      >
        <span className='svg-icon svg-icon-md svg-icon-primary'>
          <OverlayTrigger
            placement='auto'
            overlay={
              <Tooltip id='prev-week'>
                <FormattedMessage id='FAKES.TABLE.ACTIONS.EDIT' />
              </Tooltip>
            }
          >
            <SVG src={toAbsoluteUrl('/media/svg/icons/Write.svg')} />
          </OverlayTrigger>
        </span>
      </a>
      <> </>

      <a
        className='btn btn-icon btn-light btn-hover-danger btn-sm'
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();
          openDeleteFakeDialog(row._id);
        }}
      >
        <span className='svg-icon svg-icon-md svg-icon-danger'>
          <OverlayTrigger
            placement='auto'
            overlay={
              <Tooltip id='prev-week'>
                <FormattedMessage id='FAKES.TABLE.ACTIONS.DELETE' />
              </Tooltip>
            }
          >
            <SVG src={toAbsoluteUrl('/media/svg/icons/Trash.svg')} />
          </OverlayTrigger>
        </span>
      </a>
    </>
  );
}
