import { EnhancedStore } from "@reduxjs/toolkit";
import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosStatic } from "axios";

export default function setupAxios(axios: AxiosStatic, store: EnhancedStore) {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.headers["x-api-key"] = window._env_.REACT_APP_API_KEY;
      return config;
    },
    (err: AxiosError) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (err: AxiosError) => Promise.reject(err)
  );
}
