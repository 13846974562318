import React, { useCallback, useEffect, useMemo } from 'react';
import BootstrapTable, { BootstrapTableProps, PaginationOptions } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/Fakes/fakesActions';
import { sortCaret, headerSortingClasses } from '../../Helpers/TableSortingHelpers';
import { getSelectRow } from '../../Helpers/TableRowSelectionHelpers';
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from '../../Helpers/TablePaginationHelpers';
import * as uiHelpers from '../FakesUIHelpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../Helpers/Pagination';
import { Fake, FakesContext, useFakesUIContext } from '../FakesUIContext';
import { RootState, AppDispatch } from '../../../../redux/store';
import { useIntl } from 'react-intl';

interface PaginationChildProps extends PaginationOptions {
  tableId?: string;
  bootstrap4?: boolean;
}

interface PaginationProps {
  paginationProps: PaginationChildProps;
  paginationTableProps: BootstrapTableProps;
}

export function FakesTable() {
  // Fakes UI Context
  const fakesUIContext: Pick<
    FakesContext,
    'queryParams' | 'ids' | 'setIds' | 'setQueryParams' | 'openEditFakeDialog' | 'openDeleteFakeDialog'
  > = useFakesUIContext();
  const fakesUIProps: Pick<
    FakesContext,
    'queryParams' | 'ids' | 'setIds' | 'setQueryParams' | 'openEditFakeDialog' | 'openDeleteFakeDialog'
  > = useMemo(() => {
    return {
      ids: fakesUIContext.ids,
      setIds: fakesUIContext.setIds,
      queryParams: fakesUIContext.queryParams,
      setQueryParams: fakesUIContext.setQueryParams,
      openEditFakeDialog: fakesUIContext.openEditFakeDialog,
      openDeleteFakeDialog: fakesUIContext.openDeleteFakeDialog,
    };
  }, [fakesUIContext]);

  const intl = useIntl();

  // Getting curret state of fakes list from store (Redux)
  const { currentState } = useSelector((state: RootState) => ({ currentState: state.fakes }), shallowEqual);
  const { totalCount, entities, listLoading } = currentState;

  // Fakes Redux state
  const dispatch = useDispatch<AppDispatch | any>();

  const fecthAllFakes = useCallback(() => {
    dispatch(actions.fetchFakes(fakesUIProps.queryParams));
  }, [dispatch, fakesUIProps.queryParams]);

  useEffect(() => {
    // clear selections list
    fakesUIProps.setIds([]);
    // server call by queryParams
    fecthAllFakes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakesUIProps.setIds, fecthAllFakes]);

  // Table columns
  const columns = [
    {
      dataField: 'date',
      text: intl.formatMessage({
        id: 'TEXT.DATE',
      }),
      formatter: columnFormatters.DateColumnFormatter,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'title',
      text: intl.formatMessage({
        id: 'TEXT.TITLE',
      }),
      formatter: columnFormatters.TitleColumnFormatter,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'category',
      text: intl.formatMessage({
        id: 'TEXT.CATEGORY',
      }),
      formatter: columnFormatters.TextColumnFormatter,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'tags',
      text: intl.formatMessage({
        id: 'TEXT.TAGS',
      }),
      hidden: true,
      formatter: columnFormatters.TagsColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'file',
      text: intl.formatMessage({
        id: 'TEXT.FILE',
      }),
      formatter: columnFormatters.FileColumnFormatter,
      sortCaret: sortCaret,
    },
    {
      dataField: 'image',
      text: intl.formatMessage({
        id: 'TEXT.IMAGE',
      }),
      hidden: true,
      formatter: columnFormatters.ImageColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'imageSmall',
      text: intl.formatMessage({
        id: 'TEXT.THUMBNAIL',
      }),
      hidden: true,
      formatter: columnFormatters.ImageColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'url',
      text: intl.formatMessage({
        id: 'TEXT.URL',
      }),
      hidden: true,
      formatter: columnFormatters.LinkColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: 'action',
      text: intl.formatMessage({
        id: 'TEXT.ACTIONS',
      }),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditFakeDialog: fakesUIProps.openEditFakeDialog,
        openDeleteFakeDialog: fakesUIProps.openDeleteFakeDialog,
      },
      classes: 'text-right pr-0',
      headerClasses: 'text-right pr-3',
      style: {
        minWidth: '100px',
      },
    },
  ];

  const rowEvents = {
    onDoubleClick: (e: MouseEvent, row: Fake) => {
      fakesUIProps.openEditFakeDialog(row._id);
    },
  };

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    limit: fakesUIProps.queryParams.limit,
    page: fakesUIProps.queryParams.page,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }: PaginationProps) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table-sm table-head-custom table-vertical-center overflow-hidden'
                bootstrap4
                remote
                keyField='_id'
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                rowEvents={rowEvents}
                onTableChange={getHandlerTableChange(fakesUIProps.setQueryParams)}
                selectRow={getSelectRow({
                  entities,
                  ids: fakesUIProps.ids,
                  setIds: fakesUIProps.setIds,
                })}
                {...(paginationTableProps as any)}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
