import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

export interface User {
  username: string;
  email: string;
  accessToken: string;
  expires: string;
}

export interface AuthState {
  user?: User;
  authToken?: string;
}

export interface AuthStateAction {
  type: string;
  payload: AuthState;
}

interface AuthActions {
  login: Function;
  logout: Function;
  requestUser: Function;
  fulfillUser: Function;
  setUser: Function;
}

export enum actionTypes {
  Login = '[Login] Action',
  Logout = '[Logout] Action',
  UserRequested = '[Request User] Action',
  UserLoaded = '[Load User] Auth API',
  SetUser = '[Set User] Action',
}

const initialAuthState: AuthState = {};

export const reducer = persistReducer(
  { storage, key: 'vNwt-auth', whitelist: ['user', 'authToken'] },
  (state: AuthState = initialAuthState, action: AuthStateAction) => {
    switch (action.type) {
      case actionTypes.Login: {
        const payload = action.payload;

        return { authToken: payload?.authToken };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const payload = action.payload;
        return { ...state, user: payload?.user };
      }

      case actionTypes.SetUser: {
        const payload = action.payload;
        return { ...state, user: payload?.user };
      }

      default:
        return state;
    }
  },
);

export const actions: AuthActions = {
  login: (user: User) => ({
    type: actionTypes.Login,
    payload: { user },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user: User) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user: User) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user: User) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* userRequested(state: AuthStateAction) {
    yield put(actions.fulfillUser(state.payload?.user));
  });
}
