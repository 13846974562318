import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as actions from "../../../../redux/Fakes/fakesActions";
import { FakesContext, useFakesUIContext } from "../FakesUIContext";
import { AppDispatch, RootState } from "../../../../redux/store";

interface DeleteDialog {
  id?: string | null;
  show: boolean;
  path: string | undefined;
  onHide: () => void;
}

export function FakeDeleteDialog({ id, show, path, onHide }: DeleteDialog) {
  // Fakes UI Context
  const fakesUIContext: Pick<
    FakesContext,
    "queryParams" | "setIds" | "week"
  > = useFakesUIContext();
  const fakesUIProps: Pick<
    FakesContext,
    "queryParams" | "setIds" | "week"
  > = useMemo(() => {
    return {
      setIds: fakesUIContext.setIds,
      queryParams: fakesUIContext.queryParams,
      week: fakesUIContext.week,
    };
  }, [fakesUIContext]);

  // Fakes Redux state
  const dispatch = useDispatch<AppDispatch | any>();
  const { isLoading } = useSelector(
    (state: RootState) => ({ isLoading: state.fakes.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteFake = () => {
    // server request for deleting fake by id
    if (id) {
      dispatch(actions.deleteFake(id)).then(() => {
        // refresh list after deletion
        let search = fakesUIProps.queryParams;
        if (path?.includes("/weeks")) {
          search = {
            ...fakesUIProps.queryParams,
            week: fakesUIProps.week,
            allDays: true,
          };
        }
        dispatch(actions.fetchFakes(search));
        // clear selections list
        fakesUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="modal-sizes-title-lg">
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="modal-sizes-title-lg">Fake Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this fake?</span>
        )}
        {isLoading && <span>Fake is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteFake}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
