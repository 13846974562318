import React, { useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { FakesLoadingDialog } from '../Fakes/fakes-loading-dialog/FakesLoadingDialog';
import { FakeEditDialog } from '../Fakes/fake-edit-dialog/FakeEditDialog';
import { FakeDeleteDialog } from '../Fakes/fake-delete-dialog/FakeDeleteDialog';
import { FakesDeleteDialog } from '../Fakes/fakes-delete-dialog/FakesDeleteDialog';
import { FakesUIEvents, FakesUIProvider } from '../Fakes/FakesUIContext';
import { FakesContent } from '../Fakes/FakesContent';
import Alerts, { AlertsRefObject } from '../Alerts/Alert';
import { History } from 'history';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../redux/store';

interface FakesPageProps {
  history: History;
}

export function FakesPage({ history }: FakesPageProps) {
  const alertRef = useRef<AlertsRefObject | null>(null);
  const alert = alertRef?.current as AlertsRefObject;

  const { errors } = useSelector(
    (state: RootState) => ({
      errors: state.fakes?.error,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (alert && errors) alert.showAlert(errors, 'error');
  }, [alert, errors]);

  const fakesUIEvents: FakesUIEvents = {
    newFakeButtonClick: () => {
      history.push('/fakes/new');
    },
    openEditFakeDialog: (id?: string) => {
      history.push(`/fakes/${id}/edit`);
    },
    openDeleteFakeDialog: (id?: string) => {
      history.push(`/fakes/${id}/delete`);
    },
    openDeleteFakesDialog: () => {
      history.push(`/fakes/deleteFakes`);
    },
    openFetchFakesDialog: () => {
      history.push(`/fakes/fetch`);
    },
  };

  return (
    <FakesUIProvider fakesUIEvents={fakesUIEvents}>
      <Alerts ref={alertRef} show={false} />
      <FakesLoadingDialog />
      <Route path='/fakes/new'>
        {({ history, match }) => (
          <FakeEditDialog
            alertRef={alert}
            show={match != null}
            path={match?.path}
            onHide={() => {
              history.push('/fakes');
            }}
          />
        )}
      </Route>
      <Route path='/fakes/:id/edit'>
        {({ history, match }) => (
          <FakeEditDialog
            alertRef={alert}
            show={match != null}
            path={match?.path}
            id={match && match?.params?.id}
            onHide={() => {
              history.push('/fakes');
            }}
          />
        )}
      </Route>
      <Route path='/fakes/deleteFakes'>
        {({ history, match }) => (
          <FakesDeleteDialog
            show={match != null}
            path={match?.path}
            onHide={() => {
              history.push('/fakes');
            }}
          />
        )}
      </Route>
      <Route path='/fakes/:id/delete'>
        {({ history, match }) => (
          <FakeDeleteDialog
            show={match != null}
            id={match?.params?.id}
            path={match?.path}
            onHide={() => {
              history.push('/fakes');
            }}
          />
        )}
      </Route>
      <Route path='/fakes'>{({ match }) => <FakesContent path={match?.path} />}</Route>
    </FakesUIProvider>
  );
}
