import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export function Logout() {
  return (
    <>
      <OverlayTrigger
        placement='right'
        overlay={
          <Tooltip id='quick-user-tooltip'>
            <FormattedMessage id='MENU.LOGOUT' />
          </Tooltip>
        }
      >
        <NavLink to='/logout' className='nav-link btn btn-icon btn-clean btn-lg mb-10 mb-md-2'>
          <span className='symbol symbol-30 symbol-lg-40'>
            <span className='svg-icon svg-icon-lg'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/User.svg')} />
            </span>
          </span>
        </NavLink>
      </OverlayTrigger>
    </>
  );
}
