// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_metronic/layout';
import * as auth from '../../../redux/Auth/authRedux';
import { AuthState } from '../../../redux/Auth/authRedux';
import { Auth } from 'aws-amplify';

class Logout extends Component {
  signOut = async () => {
    try {
      const authInstance = window?.gapi?.auth2?.getAuthInstance();
      if (authInstance) {
        await authInstance.signOut();
        await authInstance.disconnect();
      }
      await Auth.signOut({ global: true });
    } catch (error) {
      console.warn('error signing out: ', error);
    }
  };

  componentDidMount() {
    this.signOut();
    this.props.logout();
  }

  render() {
    const { hasAuthToken }: string = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to='/auth/login' />;
  }
}

export default connect(({ auth }: AuthState) => ({ hasAuthToken: Boolean(auth.authToken) }), auth.actions)(Logout);
