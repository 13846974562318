export const AmplifyConfig = {
  Auth: {
    region: window._env_.REACT_APP_COGNITO_REGION,
    userPoolId: window._env_.REACT_APP_COGNITO_USERPOOLID,
    userPoolWebClientId: window._env_.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
    oauth: {
      domain: window._env_.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: window._env_.REACT_APP_COGNITO_REDIRECTSIGNIN,
      redirectSignOut: window._env_.REACT_APP_COGNITO_REDIRECTSIGNOUT,
      responseType: 'token',
    },
  },
};
