/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_metronic/layout/_core/MetronicLayout';
import { Logout } from './Logout';
import { Brand } from '../Brand/Brand';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';
import KTLayoutAside from '../../../_metronic/_assets/js/layout/base/aside';
import { FormattedMessage } from 'react-intl';

export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses('aside', true),
      asideSecondaryDisplay: objectPath.get(uiService.config, 'aside.secondary.display'),
      extrasUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    };
  }, [uiService]);

  const asideToggle = () => {
    const toggleElement = KTLayoutAside.getOffcanvas();
    if (toggleElement.isShown()) toggleElement.hide();
  };

  return (
    <>
      {/* begin::Aside */}
      <div id='kt_aside' className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}>
        {/* begin::Primary */}
        <div className='aside-primary d-flex flex-column align-items-center flex-row-auto'>
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className='aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull'>
            {/* begin::Nav */}
            <ul className='list-unstyled flex-column' role='tablist'>
              {/* begin::Item */}
              <li className='nav-item mb-3' data-toggle='tooltip' data-placement='rigth' data-container='body' data-boundary='window'>
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip id='fakes-week'>
                      <FormattedMessage id='MENU.WEEK' />
                    </Tooltip>
                  }
                >
                  <NavLink to='/weeks' onClick={asideToggle} className='nav-link btn btn-icon btn-clean btn-lg'>
                    <span className='svg-icon svg-icon-lg'>
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Clock.svg')} />
                    </span>
                  </NavLink>
                </OverlayTrigger>
              </li>
              {/* end::Item */}
              {/* begin::Item */}
              <li className='nav-item mb-3' data-toggle='tooltip' data-placement='rigth' data-container='body' data-boundary='window'>
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip id='fakes-list'>
                      <FormattedMessage id='MENU.LIST' />
                    </Tooltip>
                  }
                >
                  <NavLink to='/fakes' onClick={asideToggle} className='nav-link btn btn-icon btn-clean btn-lg'>
                    <span className='svg-icon svg-icon-lg'>
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Search.svg')} />
                    </span>
                  </NavLink>
                </OverlayTrigger>
              </li>
              {/* end::Item */}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className='aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10'>
            {/* begin::Logout*/}
            {<Logout />}
            {/* end::Logout */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}
      </div>
      {/* end::Aside */}
    </>
  );
}
