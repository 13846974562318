import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../redux/Auth/authRedux";
import { fakesSlice } from "./Fakes/fakesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  fakes: fakesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
