import React, { ChangeEvent } from "react";
import { Fake } from "../Fakes/FakesUIContext";

interface SelectionCheckboxProps {
  isSelected: boolean;
  onChange: (event: ChangeEvent) => void;
}

interface GroupingItemOnSelectProps {
  fakeId: string;
  ids: string[];
  setIds: (ids: string[]) => void;
}

interface GroupingAllOnSelectProps {
  isSelected: boolean;
  entities: Fake[];
  setIds: (ids: string[]) => void;
}

interface SelectRowProps {
  entities: Fake[];
  ids: string[];
  setIds: (ids: string[]) => void;
}

interface SelectionRendererProps {
  rowIndex: number;
}

function SelectionCheckbox({ isSelected, onChange }: SelectionCheckboxProps) {
  return (
    <>
      <input type="checkbox" style={{ display: "none" }} />
      <label className="checkbox checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>
    </>
  );
}

function groupingItemOnSelect(props: GroupingItemOnSelectProps) {
  const { ids, setIds, fakeId } = props;
  if (ids.some((id: string) => id === fakeId)) {
    setIds(ids.filter((id: string) => id !== fakeId));
  } else {
    const newIds = [...ids];
    newIds.push(fakeId);
    setIds(newIds);
  }
}

function groupingAllOnSelect(props: GroupingAllOnSelectProps) {
  const { isSelected, setIds, entities } = props;
  if (!isSelected) {
    const allIds: string[] = [];
    entities.forEach((el: Fake) => allIds.push(el._id));
    setIds(allIds);
  } else {
    setIds([]);
  }

  return isSelected;
}

export function getSelectRow(props: SelectRowProps) {
  const { entities, ids, setIds } = props;
  return {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    selectionHeaderRenderer: () => {
      const isSelected =
        entities && entities.length > 0 && entities.length === ids.length;
      const props = { isSelected, entities, setIds };
      return (
        <SelectionCheckbox
          isSelected={isSelected}
          onChange={() => groupingAllOnSelect(props)}
        />
      );
    },
    selectionRenderer: ({ rowIndex }: SelectionRendererProps) => {
      if (entities[rowIndex]) {
        const isSelected = ids.some(
          (el: string) => el === entities[rowIndex]._id
        );
        const props = { ids, setIds, fakeId: entities[rowIndex]._id };
        return (
          <SelectionCheckbox
            isSelected={isSelected}
            onChange={() => groupingItemOnSelect(props)}
          />
        );
      }
    },
  };
}
