import { Link } from "@material-ui/core";
import React from "react";

export function LinkColumnFormatter(cellContent: string) {
  return (
    <Link href={cellContent} target="_blank" rel="noreferrer">
      {cellContent}
    </Link>
  );
}
