import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ModalProgressBar } from '../../../../_metronic/_partials/controls';
import { RootState } from '../../../../redux/store';
import { useIntl } from 'react-intl';

interface EditDialogHeader {
  id?: string | null;
}

export function FakeEditDialogHeader({ id }: EditDialogHeader) {
  // Fakes Redux state
  const { fakeForEdit, actionsLoading } = useSelector(
    (state: RootState) => ({
      actionsLoading: state.fakes.actionsLoading,
      fakeForEdit: state.fakes?.fakeForEdit,
    }),
    shallowEqual,
  );

  const [title, setTitle] = useState('');

  const intl = useIntl();

  // Title couting
  useEffect(() => {
    let _title = id
      ? ''
      : intl.formatMessage({
          id: 'FAKES.HEADER.NEW.FAKE',
        });
    if (fakeForEdit) {
      _title = `${intl.formatMessage({
        id: 'FAKES.HEADER.EDIT.FAKE',
      })} ${fakeForEdit?._id}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [fakeForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header className='modal-header' closeButton>
        <Modal.Title id='modal-sizes-title-lg'>{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
