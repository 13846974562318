import axios from 'axios';
import Bluebird from 'bluebird';
import { Fake } from '../app/components/Fakes/FakesUIContext';
import { Filters } from '../app/components/Fakes/FakesUIHelpers';

const FAKES_URL = `${window._env_.REACT_APP_BACKEND}/fakes/`;

// CREATE =>  POST: add a new fake to the server
export function createFake(fake: Fake) {
  const formData = new FormData();
  Object.entries(fake).forEach(item => {
    formData.append(item[0], item[1]);
  });
  return axios.post(FAKES_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

// READ
export function getAllFakes() {
  return axios.get(FAKES_URL);
}

export function getFakeById(fakeId: string) {
  return axios.get(fakeId, { baseURL: FAKES_URL });
}

export function findFakes(queryParams: Filters) {
  return axios.get(FAKES_URL, { params: queryParams });
}

// UPDATE => PUT: update the fake on the server
export function updateFake(fake: Fake) {
  const formData = new FormData();
  Object.entries(fake).forEach(item => {
    formData.append(item[0], item[1]);
  });
  return axios.patch(fake._id, formData, {
    baseURL: FAKES_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

// DELETE => delete the fake from the server
export function deleteFake(fakeId: string) {
  return axios.delete(fakeId, { baseURL: FAKES_URL });
}

// DELETE Fakes by ids
export async function deleteFakes(ids: string[]) {
  await Bluebird.map(ids, deleteFake);
}
